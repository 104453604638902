import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import 'react-dropzone-uploader/dist/styles.css';
import Img from 'react-image';
import { rem } from 'polished';
import { Button, Message, Label, Loader, Icon } from 'oxyrion-ui/lib';
import { connect } from '../../Store';
import { __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import {
  LoaderWrapper,
  StyledInput,
} from '../../Components/ReusableComponents';
import AdminAPI from '../../AdminAPI';
import SearchAndOrderItems from '../../Components/SearchAndOrderItems';
import LanguageSwitch from '../../Components/LanguageSwitch';
import SettingsBox from '../../Components/SettingsBox';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import API2 from '../../API2';
import DraggableList from 'react-draggable-list';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  //width: 100%;
  padding-left: ${rem(20)};
  padding-right: ${rem(20)};
  margin-bottom: ${rem(20)};
  margin-right: ${rem(20)};
  flex-direction: column;
`;

const InputWrapper = styled.div`
  width: ${rem(500)};
`;

const Space = styled.div`
  width: ${rem(12)};
  height: ${rem(12)};
`;

const Row = styled.div`
  margin-bottom: ${rem(8)};
  // margin-right: ${rem(16)};
  display: flex;
`;

const StyledLabel = styled(Label)`
  margin-right: ${rem(4)};
`;

const SearchableAndOrderSelectWrapper = styled.div`
  width: ${rem(1000)};
`;

const DragWrapper = styled.div`
  display: flex;
  position: relative;

  .drag_and_drop_wrapper {
    background: #f7f7f7;
    border-radius: ${rem(4)};
    padding: ${rem(8)};
    margin-bottom: ${rem(20)};
    overflow: auto;
    flex-direction: column;
    min-width: ${rem(210)};
  }
`;

const DragDepartmentItem = styled.div`
  width: 280px;
  height: 20px;
  border-radius: 4px;
  padding: ${rem(10)};
  background: white;
  margin-right: ${rem(12)};
  margin-bottom: ${rem(8)};
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  box-shadow: 0 ${rem(3)} ${rem(5)} 0 rgba(0, 0, 0, 0.16);
  position: relative;
`;

const DragItem = styled.div`
  width: ${rem(300)};
  height: ${rem(80)};
  border-radius: 4px;
  padding: ${rem(10)};
  background: white;
  margin-right: ${rem(12)};
  margin-bottom: ${rem(8)};
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  box-shadow: 0 ${rem(3)} ${rem(5)} 0 rgba(0, 0, 0, 0.16);
  position: relative;

  .picture {
    display: flex;
  }

  .description {
    padding-left: ${rem(8)};
    padding-right: ${rem(16)};
  }

  .description-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .icon-wrapper {
    cursor: pointer;
  }
`;

const PopUpInsideWrapper = styled.div`
  padding: ${rem(12)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
`;

const ButtonWrapper = styled.div`
  padding-top: ${rem(12)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
`;

const Photo = styled(Img)`
  width: ${rem(64)};
  // border-radius: 100%;
`;

const COUNTRIES_TO_PUBLISH = ['SK', 'CZ', 'EN', 'AT'];
class CompanyInfoTeamMembers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      saveLoading: false,
      teamLoading: false,
      showAddTeam: false,
      success: false,
      activeLang: 'SK',
      teamMembers: [],
      empolyersInTeam: [],
      employers: [],
      data: {
        employer_section: null,
        employer: null,
        newTeamName: '',
        teamName: '',
      },
    };
  }

  componentWillMount() {
    this.loadData();
  }

  getTranslatedData(data, lang) {
    if (!data) {
      return '';
    }

    const obj = data.find((d) => d.lang === lang);
    return obj ? obj.value : '';
  }

  async handleChange(field, value) {
    const { data, activeLang, employers, teamMembers } = this.state;

    if (value) {
      if (field === 'employer_section') {
        data[field] = value;
        data.teamName = this.getTranslatedData(value.raw.title, activeLang);
        const membersIds = this.getTranslatedData(
          value.raw.members,
          //teamMembers.find((t) => t._id === value._id).members,
          activeLang,
        );

        this.setState({
          data,
          empolyersInTeam:
            employers
              .filter((f) => membersIds.includes(f.value))
              .sort(
                (a, b) =>
                  membersIds.indexOf(a.value) - membersIds.indexOf(b.value),
              )
              .map((t, index) => Object.assign(t, { id: index })) || [],
        });
      } else if (field === 'employer') {
        data[field] = value;

        this.setState(
          {
            data,
          },
          () => this.addEmployerToTeam(true),
        );
      } else {
        data[field] = value;
        this.setState({ data });
      }
    } else {
      data[field] = null;
      this.setState({
        data,
      });
    }
  }

  async handleChangeActiveLang(lang) {
    const { data } = this.state;

    this.setState(
      {
        activeLang: lang,
      },
      () => {
        this.handleChange('employer_section', {
          label: this.getTranslatedData(data.employer_section.raw.title, 'SK'),
          value: this.getTranslatedData(data.employer_section.raw.title, lang),
          _id: data.employer_section._id,
          raw: data.employer_section.raw,
        });
      },
    );

    return true;
  }

  async addEmployerToTeam(forceSave = false) {
    const { teamMembers, activeLang, data, employers } = this.state;

    try {
      if (forceSave) {
        this.setState({
          saveLoading: true,
        });
      }

      const team = teamMembers.find(
        (section) => section._id === data.employer_section._id,
      );

      if (team) {
        if (data.employer) {
          const member = team.members.find((tm) => tm.lang === activeLang);

          if (member && member.value) {
            member.value.push(data.employer.value);
          } else {
            team.members.push({
              lang: activeLang,
              value: [data.employer.value],
            });
          }
        }

        const title = team.title.find((tm) => tm.lang === activeLang);

        if (title && title.value) {
          title.value = data.teamName;
        } else {
          team.title.push({
            lang: activeLang,
            value: data.teamName,
          });
        }
      }

      const params = {};
      params.body = Object.assign({
        team_members: teamMembers.map((t) => ({
          _id: t._id,
          title: t.title,
          members: t.members,
        })),
      });

      if (forceSave) {
        await AdminAPI.putCompanyBaseInfoAction(params);
      }

      data.employer = null;

      const membersIds =
        employers && team.members
          ? this.getTranslatedData(team.members, activeLang)
          : null;

      this.setState({
        data,
        empolyersInTeam:
          membersIds && employers
            ? employers
                .filter((f) => membersIds.includes(f.value))
                .sort(
                  (a, b) =>
                    membersIds.indexOf(a.value) - membersIds.indexOf(b.value),
                )
                .map((t, index) => Object.assign(t, { id: index }))
            : [],
        success: forceSave ? __('Dáta boli úspešne uložené') : null,
        saveLoading: false,
        teamMembers,
      });
    } catch (e) {
      console.log(e);
      if (forceSave) {
        this.setState({
          saveLoading: false,
        });
      }
    }
  }

  async addTeam() {
    const { teamMembers, data } = this.state;

    try {
      this.setState({
        teamLoading: true,
      });

      const params = {};
      params.body = {
        team_members: teamMembers
          .map((t) => ({
            _id: t._id,
            title: t.title,
            members: t.members,
          }))
          .concat([
            {
              _id: new Date().getTime().toString(),
              title: [{ lang: 'SK', value: data.newTeamName }],
              members: [{ lang: 'SK', value: [] }],
            },
          ]),
      };

      const response = await AdminAPI.putCompanyBaseInfoAction(params);

      data.newTeamName = '';

      this.loadData();

      this.setState({
        success: __('Dáta boli úspešne uložené'),
        teamMembers:
          response.team_members.map((t, index) =>
            Object.assign(t, { id: index }),
          ) || [],
        teamLoading: false,
        showAddTeam: false,
        data,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        teamLoading: false,
        showAddTeam: false,
      });
    }
  }

  async deleteTeamMember(memberToDelete) {
    const { teamMembers, data, activeLang, employers } = this.state;

    let _teamMembers = [...teamMembers];

    const sectionIndex = teamMembers.findIndex(
      (section) => section._id === data.employer_section._id,
    );

    const findMemberIndex = _teamMembers[sectionIndex].members.findIndex(
      (item) => item.lang === activeLang,
    );

    const filteredMembers = _teamMembers[sectionIndex].members[
      findMemberIndex
    ].value.filter((memberId) => memberId !== memberToDelete.value);

    _teamMembers[sectionIndex].members[findMemberIndex].value = filteredMembers;

    try {
      const params = {};

      params.body = Object.assign({
        team_members: _teamMembers.map((t) => ({
          _id: t._id,
          title: t.title,
          members: t.members,
        })),
      });

      AdminAPI.putCompanyBaseInfoAction(params);
    } catch (e) {
      console.log(e);
    }

    this.handleChange('employer_section', {
      label: this.getTranslatedData(data.employer_section.raw.title, 'SK'),
      value: this.getTranslatedData(
        data.employer_section.raw.title,
        activeLang,
      ),
      _id: data.employer_section._id,
      raw: _teamMembers[sectionIndex],
    });

    this.setState({
      success: __('Dáta boli úspešne uložené'),
      teamMembers: _teamMembers,
    });
  }

  async loadData() {
    const { activeLang } = this.state;
    try {
      this.setState({
        loading: true,
      });

      const [response, employers] = await Promise.all([
        AdminAPI.getCompanyBaseInfoAction({}),
        this.fetchEmployers(undefined, '', 1000),
      ]);

      response.team_members.forEach((tm) => {
        COUNTRIES_TO_PUBLISH.forEach((lang) => {
          if (!tm.title.some((t) => t.lang === lang)) {
            tm.title.push({
              lang: lang,
              value: this.getTranslatedData(tm.title, 'SK'),
            });
          }
          if (!tm.members.some((t) => t.lang === lang)) {
            tm.members.push({
              lang: lang,
              value: [],
            });
          }
        });
      });

      this.setState({
        loading: false,
        employers,
        teamMembers:
          response.team_members.map((t, index) =>
            Object.assign(t, { id: index }),
          ) || [],
      });

      const defaultSelectedTeam =
        response.team_members &&
        response.team_members[0] &&
        this.getTranslatedData(response.team_members[0].title, activeLang);

      if (defaultSelectedTeam) {
        this.handleChange('employer_section', {
          label: defaultSelectedTeam,
          value: defaultSelectedTeam,
          _id: response.team_members[0]._id,
          raw: response.team_members[0],
        });
      }
    } catch (e) {
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
        loading: false,
      });
    }
  }

  fetchUsers(query) {
    try {
      return API2.getAllUsersAction({
        q: query,
        limit: 15,
      }).then((res) => {
        return res.users.map((item) => ({
          value: item._id,
          label: item.display_name,
          raw: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async fetchEmployers(lang, query, limit = 15) {
    try {
      return await AdminAPI.getEmployersAction(this.props.firm, {
        limit,
        offset: 0,
        q: query,
        publish: lang,
      }).then((res) => {
        return res.items.map((item) => ({
          value: item._id,
          raw: item,
          label: `${item.name} ${item.surname}`,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async fetchSections(query, limit = 15) {
    try {
      return await AdminAPI.getCompanyBaseInfoAction({
        limit,
        offset: 0,
        q: query,
      }).then((response) => {
        response.team_members.forEach((tm) => {
          COUNTRIES_TO_PUBLISH.forEach((lang) => {
            if (!tm.title.some((t) => t.lang === lang)) {
              tm.title.push({
                lang: lang,
                value: this.getTranslatedData(tm.title, 'SK'),
              });
            }
            if (!tm.members.some((t) => t.lang === lang)) {
              tm.members.push({
                lang: lang,
                value: [],
              });
            }
          });
        });

        return response.team_members.map((item) => ({
          value: this.getTranslatedData(item.title, 'SK'),
          label: this.getTranslatedData(item.title, 'SK'),
          raw: item,
          _id: item._id,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  onEmployersListChange = (newList) => {
    const { teamMembers, data, activeLang } = this.state;

    const team = teamMembers.find(
      (section) => section._id === data.employer_section._id,
    );

    if (team) {
      const member = team.members.find((tm) => tm.lang === activeLang);

      if (member && member.value) {
        member.value = newList.map((i) => i.value);
      }
    }

    this.handleChange('employer_section', {
      label: this.getTranslatedData(data.employer_section.raw.title, 'SK'),
      value: this.getTranslatedData(
        data.employer_section.raw.title,
        activeLang,
      ),
      _id: data.employer_section._id,
      raw: team,
    });

    try {
      const params = {};
      params.body = {
        team_members: teamMembers.map((t) => ({
          _id: t._id,
          title: t.title,
          members: t.members,
        })),
      };

      AdminAPI.putCompanyBaseInfoAction(params);
    } catch (e) {
      console.log(e);
    }
  };

  onDepartmentListChange = (newList) => {
    this.setState({
      teamMembers: newList,
    });

    try {
      const params = {};
      params.body = {
        team_members: newList.map((t) => ({
          _id: t._id,
          title: t.title,
          members: t.members,
        })),
      };
      AdminAPI.putCompanyBaseInfoAction(params);
    } catch (e) {
      console.log(e);
    }
  };

  renderControlBar() {
    const { history } = this.props;

    return (
      <ControllBar history={history} name={__('Štruktúra zamestnancov')}>
        <ControlBarButton
          primary
          small
          onClick={() =>
            this.setState({
              showAddTeam: true,
            })
          }
          showIcon
        >
          {__('Pridať oddelenie')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    const {
      loading,
      error,
      success,
      saveLoading,
      data,
      activeLang,
      teamLoading,
      showAddTeam,
      teamMembers,
      empolyersInTeam,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <PopUpWrapper
          display={showAddTeam}
          message={__('Pridať nové oddelenie')}
          small
          onClose={() =>
            this.setState({
              showAddTeam: false,
            })
          }
        >
          <PopUpInsideWrapper>
            <StyledInput
              style={{ width: '100%' }}
              value={data.newTeamName}
              placeholder={__('Názov')}
              onChange={(e) => this.handleChange('newTeamName', e.target.value)}
            />
            <ButtonWrapper>
              <Button
                small
                disabled={!data.newTeamName}
                loading={teamLoading}
                onClick={() => this.addTeam()}
                primary
              >
                {__('Uložiť')}
              </Button>
            </ButtonWrapper>
          </PopUpInsideWrapper>
        </PopUpWrapper>

        <Wrapper>
          {error && (
            <Message
              error
              message={
                error.length ? error : __('Pri ukladaní dát sa vyskytla chyba')
              }
            />
          )}
          {success && <Message success message={success} />}
        </Wrapper>

        <ContentWrapper>
          <Space />
          <SettingsBox addDisabled>
            <Row>
              <InputWrapper>
                <StyledLabel>{__('Zvolené oddelenie')}</StyledLabel>
                <SearchableAndOrderSelectWrapper>
                  <SearchAndOrderItems
                    style={{ width: rem(430) }}
                    value={data.employer_section}
                    loadOptions={(query) => {
                      return this.fetchSections(query, 20);
                    }}
                    handleOnChange={(e) =>
                      this.handleChange('employer_section', e)
                    }
                    placeholder={__('Vyberte oddelenie')}
                    isMulti={false}
                    clearable={false}
                  />
                </SearchableAndOrderSelectWrapper>
              </InputWrapper>

              <InputWrapper>
                <StyledLabel>{__('Zoradenie oddelení: ')}</StyledLabel>

                <DraggableList
                  itemKey="_id"
                  list={teamMembers.map((item, index) => ({
                    ...item,
                  }))}
                  onMoveEnd={this.onDepartmentListChange}
                  template={({ item, itemSelected, dragHandleProps }) => (
                    <DragDepartmentItem {...dragHandleProps}>
                      {this.getTranslatedData(item.title, 'SK')}
                    </DragDepartmentItem>
                  )}
                  commonProps={{}}
                ></DraggableList>
              </InputWrapper>
            </Row>
          </SettingsBox>

          <div>
            <Row>
              <LanguageSwitch
                onChange={(lang) => this.handleChangeActiveLang(lang)}
                activeId={activeLang}
                values={COUNTRIES_TO_PUBLISH}
              />
            </Row>

            <SettingsBox addDisabled>
              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Názov')}</StyledLabel>
                  <StyledInput
                    style={{ width: rem(380) }}
                    value={data.teamName}
                    placeholder={__('Názov')}
                    onChange={(e) =>
                      this.handleChange('teamName', e.target.value)
                    }
                  />
                </InputWrapper>
              </Row>

              <Row>
                <InputWrapper>
                  <StyledLabel>{__('Pridať zamestnanca')}</StyledLabel>
                  <SearchableAndOrderSelectWrapper>
                    <SearchAndOrderItems
                      key={activeLang}
                      value={data.employer}
                      loadOptions={(query) => {
                        return this.fetchEmployers(activeLang, query, 20);
                      }}
                      handleOnChange={(e) => this.handleChange('employer', e)}
                      placeholder={__('Vyberte zamestnanca')}
                    />
                  </SearchableAndOrderSelectWrapper>
                </InputWrapper>

                <InputWrapper>
                  <StyledLabel>{__('Zamestnanci na oddelení: ')}</StyledLabel>

                  <DraggableList
                    itemKey="id"
                    list={empolyersInTeam.map((item, index) => ({
                      ...item,
                    }))}
                    onMoveEnd={this.onEmployersListChange}
                    template={({ item, itemSelected, dragHandleProps }) => (
                      <DragItem {...dragHandleProps}>
                        <div className="picture">
                          <Photo
                            src={
                              item.raw &&
                              item.raw.profile_picture &&
                              item.raw.profile_picture.fullPath
                                ? `${process.env.REACT_APP_IMAGES_BASE_PATH}/images/64x0/${item.raw.profile_picture.fullPath}`
                                : '/images/icons/avatar.svg'
                            }
                          />
                        </div>
                        <div className="description-wrapper">
                          <div className="description">{item.label}</div>
                          <div
                            className="icon-wrapper"
                            onClick={() => this.deleteTeamMember(item)}
                          >
                            <Icon name={'close'} size="l" color="red" />
                          </div>
                        </div>
                      </DragItem>
                    )}
                    commonProps={{}}
                  ></DraggableList>
                </InputWrapper>
              </Row>

              <Space />
              <Button
                disabled={!data.employer_section}
                loading={saveLoading}
                onClick={() => this.addEmployerToTeam(true)}
                primary
              >
                {__('Uložiť')}
              </Button>
            </SettingsBox>
          </div>
        </ContentWrapper>
      </React.Fragment>
    );
  }
}

CompanyInfoTeamMembers.propTypes = {
  history: PropTypes.shape({}).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CompanyInfoTeamMembers);
